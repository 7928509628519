import React from "react"
import styled from "styled-components"

import EventForm from "../components/forms/EventsForm"
import Menu from "../components/menus/Menu"
import Layout from "../components/Layout"
import cloudinaryOptimize from "../helpers/cloudinaryOptimize"
import pdfMenu from "../assets/PDFs/DocksidePartyOptions.pdf"
import print from "../assets/PDFs/print.jpg"

const Page = styled.section`
  background-color: ${props =>
    props.theme.light ? props.theme.light : "grey"};
  padding: 2rem 1rem;
`

const PrivateEventsPackages = styled.div`
  margin-top: 3rem;
  max-width: 1200px;
  margin: 0 auto 2rem;
  @media (min-width: 767px) {
    text-align: center;
  }
`

const SectionTitle = styled.h2`
  margin: 2rem 1rem;
  font-size: 1.75rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
`

const FormContainer = styled.div`
  /* max-width: 767px; */
  padding: 2rem 1.5rem;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('${cloudinaryOptimize(
    "https://res.cloudinary.com/gonation/image/upload/v1596205445/sites/dockside-brewing/contact-bg.jpg",
    1200
  )}');
  background-position: center;
  background-size: cover;
  /* margin: 0 auto 2rem; */
  @media (min-width: 767px) {
    text-align: center;
  }
`

const CTABoxes = styled.div`
  display: flex;
  height: 200px;
  justify-content: space-between;
  justify-content: center;
  max-width: 767px;
  margin: 2rem auto;
`

const CTABox = styled.a`
  display: flex;
  margin: 0.25rem;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  width: 50%;
  height: 200px;
`

const CTATitle = styled.h2`
  font-size: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 1rem;
  text-align: center;
  color: white;
  @media (min-width: 767px) {
    font-size: 1.75rem;
  }
`

const CTAImage = styled.img`
  height: 100%;
  transition: all ease-in-out 0.5s;
  width: 100%;
  filter: brightness(0.5);
`

const BookAParty = () => {
  return (
    <Layout
      pageTitle="Book A Party"
      subHeading={`Please reach out to our events team for further information. No parties can be booked on any level other than “Grassy Knoll" and our "Private Perogola."`}
    >
      <Page>
        <PrivateEventsPackages>
          <SectionTitle>PACKAGES</SectionTitle>
          <Menu poweredListID={"powered-list-8"} mode={"cellsThenAllInOnce"} />
        </PrivateEventsPackages>
        <CTABoxes>
          <CTABox href={pdfMenu} target="_blank" rel="noopener">
            <CTAImage src={print} alt="Downloadable Menu" />
            <CTATitle>Printable Menu</CTATitle>
          </CTABox>
        </CTABoxes>
        <FormContainer>
          <SectionTitle>Make An Inquiry</SectionTitle>
          <EventForm formName="Private Events" />
        </FormContainer>
      </Page>
    </Layout>
  )
}

export default BookAParty
