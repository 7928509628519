import React from "react"
import styled from "styled-components"

export default function EventForm({ formName }) {
  return (
    <Form
      name="Private Events"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      enctype="application/x-www-form-urlencoded"
    >
      <input type="hidden" name="form-name" value="Private Events" />
      <input type="hidden" name="subject" value="FROM PRIVATE EVENTS FORM" />
      <input name="User name" id="name" mb={3} placeholder="Name" />
      <input
        type="email"
        name="User Email"
        id="email"
        mb={3}
        placeholder="Email"
      />
      <input
        type="phone"
        name="User Phone"
        id="phone"
        mb={3}
        placeholder="Phone"
      />
      <input
        type="date"
        name="Event Date"
        id="date"
        mb={3}
        placeholder="Date Of Event"
      />
      <input
        type="number"
        name="Event Guests"
        id="numberguests"
        mb={3}
        placeholder="Number Guests"
      />

      <textarea
        name="Event Message"
        id="message"
        rows="6"
        mb={3}
        placeholder="Message"
      />
      <button name="submit">Submit</button>
    </Form>
  )
}

const Form = styled.form`

max-width: 600px;
width: 100%;
margin: 0 auto;
display: flex;
flex-direction: column;
> * {
  margin-bottom: 1rem;
  background: none;
  border: solid 2px ${props =>
    props.theme.primary ? props.theme.primary : "rgb(202,54,37)"};
  border-radius: 5px;
  box-shadow: none;
}
> *:last-child {
  margin-bottom: 0;
}
> input {
  color: #fff;
  padding:0.5rem;
  height: 2rem;
  color: white;
  border: none;
  border-bottom: 1px solid #fff;
  box-shadow: 0;
  border-radius: 0;
  outline: none;
  &::placeholder {
    color: white;
  }
}
> textarea {
  color: #fff;
  padding:0.5rem;
  border: 1px solid #fff;
  box-shadow: 0;
  border-radius: 4px;
  outline: none;
  &::placeholder {
    color: #fff;
  }
}

 button {
  background: ${props =>
    props.theme.primary ? props.theme.primary : "rgb(202,54,37)"};
  padding: 1rem 0.75rem;
  border: none;
  color: white;
  font-weight: bold;
  text-transform: uppercase;

  &:hover {
    background: ${({theme}) => theme.secondary};
    transition: all .3s;
  }
}
`
